import { Component, DestroyRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnRendererComponent, SelectOption, SelectV2Module, UserState } from '@gea/digital-ui-lib';
import { FormBuilder, FormControl } from '@angular/forms';
import { DocumentDownloadDialogData } from '../document-download-dialog.component';
import { DropdownModule } from 'primeng/dropdown';
import { Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'advance-document-language-renderer',
  standalone: true,
  imports: [CommonModule, SelectV2Module, DropdownModule],
  templateUrl: './document-language-renderer.component.html',
  styleUrl: './document-language-renderer.component.scss',
})
export class DocumentLanguageRendererComponent implements ColumnRendererComponent<DocumentDownloadDialogData>, OnInit {
  userLanguage = '';
  rowData?: DocumentDownloadDialogData;

  form = this.fb.group({
    languages: new FormControl<SelectOption<string> | null>(null, []),
  });

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private destroyed: DestroyRef
  ) {}

  ngOnInit() {
    this.fetchUserLanguage();
    if (this.rowData && this.rowData.languages) {
      const userLang = this.rowData.languages.find((lang) => lang.name === this.userLanguage);
      this.rowData.selectedLanguage = userLang || this.rowData.languages[0];
    }
  }

  updateSelectedLanguage(selectedLanguage: SelectOption) {
    if (this.rowData) {
      this.rowData.selectedLanguage = selectedLanguage.value as SelectOption;
    }
  }

  fetchUserLanguage() {
    this.store
      .select(UserState.user)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((user) => {
        if (user?.language) {
          this.userLanguage = user.language.split('-')[0].toUpperCase();
        } else {
          this.userLanguage = 'EN';
        }
      });
  }
}
