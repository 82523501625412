import { Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomSheetModule, ComplexDialogV2Service, IconModule } from '@gea/digital-ui-lib';
import { DocumentDownloadDialogComponent } from './document-download-dialog/document-download-dialog.component';
import { DocumentGroup } from '../../../api/v1';
import { IsMobileViewService } from '../../../services/is-mobile-view.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'advance-document-download',
  standalone: true,
  imports: [CommonModule, BottomSheetModule, TranslateModule, IconModule, SidebarModule],
  templateUrl: './document-download.component.html',
  styleUrl: './document-download.component.scss',
})
export class DocumentDownloadComponent implements OnInit {
  selectedDocument?: DocumentGroup;

  @Input() documentGroups: DocumentGroup[] = [];
  @Input() isMobileView = false;
  @Input() showDocumentDownload = false;
  @Output() showDocumentDownloadChange = new EventEmitter<boolean>();

  constructor(
    private complexDialogService: ComplexDialogV2Service,
    private isMobileViewService: IsMobileViewService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.isMobileViewService.isMobileView$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isMobileView) => {
      this.isMobileView = isMobileView;

      if (!this.showDocumentDownload) {
        return;
      }

      // Switch from dialog to banner and back when resizing
      if (this.isMobileView) {
        this.complexDialogService.close();
      } else {
        this.selectedDocument = undefined;
        this.openDocumentDownloadDialog();
      }
    });
  }

  openDocumentDownloadDialog(): void {
    if (!this.showDocumentDownload) {
      return;
    }

    if (!this.isMobileView) {
      this.complexDialogService.open(
        {
          title: 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.TITLE',
          yes: 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.BUTTON-TITLE',
          closable: true,
          hideButtons: false,
          showRejectButton: false,
          data: this.documentGroups,
          rejectCallback: () => this.showDocumentDownloadChange.emit(false),
          confirmCallback: () => this.showDocumentDownloadChange.emit(false),
        },
        DocumentDownloadDialogComponent
      );
    }
  }

  changeSelectedDocument(document: DocumentGroup) {
    // Artificial mini delay so that the button press is visible
    setTimeout(() => {
      this.selectedDocument = document;
    }, 80);
  }

  downloadFile(downloadLink: string | undefined) {
    this.showDocumentDownloadChange.emit(false);
    window.open(downloadLink, '_blank');
  }
}
