/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type DocumentType = 'NOT_FOUND' | 'BROCHURE' | 'CAD_2D' | 'CAD_3D' | 'CORPORATE_DESIGN' | 'DRAWING' | 'INSTALLATION_INSTRUCTION' | 'LUBRICANTS' | 'MAINTENANCE_INSTRUCTIONS' | 'OPERATING_INSTRUCTION' | 'PARTS_MANUAL' | 'PICTURE' | 'PLANNING_GUIDE' | 'PRODUCT_INFORMATION' | 'QUICK_REFERENCE_GUIDE' | 'SAFETY_INSTRUCTION' | 'SHARE_BUYBACK' | 'SOFTWARE' | 'SPARE_PARTS_LIST' | 'TERMS_AND_CONDITIONS' | 'TRANSPORTATION_INSTRUCTION';

export const DocumentType = {
    NOT_FOUND: 'NOT_FOUND' as DocumentType,
    BROCHURE: 'BROCHURE' as DocumentType,
    CAD_2D: 'CAD_2D' as DocumentType,
    CAD_3D: 'CAD_3D' as DocumentType,
    CORPORATE_DESIGN: 'CORPORATE_DESIGN' as DocumentType,
    DRAWING: 'DRAWING' as DocumentType,
    INSTALLATION_INSTRUCTION: 'INSTALLATION_INSTRUCTION' as DocumentType,
    LUBRICANTS: 'LUBRICANTS' as DocumentType,
    MAINTENANCE_INSTRUCTIONS: 'MAINTENANCE_INSTRUCTIONS' as DocumentType,
    OPERATING_INSTRUCTION: 'OPERATING_INSTRUCTION' as DocumentType,
    PARTS_MANUAL: 'PARTS_MANUAL' as DocumentType,
    PICTURE: 'PICTURE' as DocumentType,
    PLANNING_GUIDE: 'PLANNING_GUIDE' as DocumentType,
    PRODUCT_INFORMATION: 'PRODUCT_INFORMATION' as DocumentType,
    QUICK_REFERENCE_GUIDE: 'QUICK_REFERENCE_GUIDE' as DocumentType,
    SAFETY_INSTRUCTION: 'SAFETY_INSTRUCTION' as DocumentType,
    SHARE_BUYBACK: 'SHARE_BUYBACK' as DocumentType,
    SOFTWARE: 'SOFTWARE' as DocumentType,
    SPARE_PARTS_LIST: 'SPARE_PARTS_LIST' as DocumentType,
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS' as DocumentType,
    TRANSPORTATION_INSTRUCTION: 'TRANSPORTATION_INSTRUCTION' as DocumentType
};

