/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductAttributeMappingType } from './productAttributeMappingType';


export interface ProductAttribute { 
    name: string;
    value: string;
    sortOrder: number;
    type: ProductAttributeMappingType;
}
export namespace ProductAttribute {
}


