import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductselectionApiService } from '../api/v1';

@Injectable({
  providedIn: 'root',
})
export class ProductSelectionService {
  constructor(private api: ProductselectionApiService) {}

  public updateSelectedProducts(registeredProductId: string): Observable<void> {
    return this.api.patchProductSelection({ registeredProductIds: [registeredProductId] }) as Observable<void>;
  }
}
