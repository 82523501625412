/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type UserStatus = 'NOT_FOUND' | 'ACTIVE_Z001' | 'LOW_UTILIZATION_Z002' | 'BEING_MANUFACTURED_Z003' | 'SPARE_STAND_BY_IDLE_Z004' | 'UNKNOWN_Z005' | 'SCRAPPED_Z006' | 'ADMIN_ERROR_Z007';

export const UserStatus = {
    NOT_FOUND: 'NOT_FOUND' as UserStatus,
    ACTIVE: 'ACTIVE_Z001' as UserStatus,
    LOW_UTILIZATION: 'LOW_UTILIZATION_Z002' as UserStatus,
    BEING_MANUFACTURED: 'BEING_MANUFACTURED_Z003' as UserStatus,
    SPARE_STAND_BY_IDLE: 'SPARE_STAND_BY_IDLE_Z004' as UserStatus,
    UNKNOWN: 'UNKNOWN_Z005' as UserStatus,
    SCRAPPED: 'SCRAPPED_Z006' as UserStatus,
    ADMIN_ERROR: 'ADMIN_ERROR_Z007' as UserStatus
};

