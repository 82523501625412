import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ControlJobRendererService {
  rowToggled$ = new Subject<void>();
  currentOpenedRows: string[] = [];

  get rowToggled(): Observable<void> {
    return this.rowToggled$.asObservable();
  }

  checkJobItem(jobName: string) {
    if (!this.currentOpenedRows.includes(jobName)) {
      this.currentOpenedRows.push(jobName);
    } else {
      const index: number = this.currentOpenedRows.indexOf(jobName);
      this.currentOpenedRows.splice(index, 1);
    }
    this.rowToggled$.next();
  }
}
