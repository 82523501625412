@if (rowData && rowData.languages) {
  <geaui-select-v2
    class="table-dropdown"
    id="languages"
    [group]="form"
    [options]="rowData?.languages ?? []"
    [disabled]="rowData.languages.length === 1"
    [selected]="rowData.selectedLanguage ?? null"
    (selectChanged)="updateSelectedLanguage($event)">
  </geaui-select-v2>
}
