/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobExecutionNotesInner } from './jobExecutionNotesInner';


export interface JobExecution { 
    id: string;
    name: string;
    traceId?: string;
    jobStatus: JobExecution.JobStatusEnum;
    manuallyStarted: boolean;
    startDate: string;
    endDate: string;
    notes?: Array<JobExecutionNotesInner>;
}
export namespace JobExecution {
    export type JobStatusEnum = 'NotFound' | 'Started' | 'Aborted' | 'Success' | 'Failed' | 'Planned';
    export const JobStatusEnum = {
        NOT_FOUND: 'NotFound' as JobStatusEnum,
        STARTED: 'Started' as JobStatusEnum,
        ABORTED: 'Aborted' as JobStatusEnum,
        SUCCESS: 'Success' as JobStatusEnum,
        FAILED: 'Failed' as JobStatusEnum,
        PLANNED: 'Planned' as JobStatusEnum
    };
}


