<geaui-page-content-layout>
  <ng-container page-content>
    <div class="scheduled-jobs-container geaui-grid geaui-sidebar-grid geaui-scrollable-table">
      <div class="gea-font-heading-24 align-center geaui-col-desktop-xl-start-7 geaui-col-desktop-md-12">
        {{ 'ASSET-APP.ADMIN-VIEW.SCHEDULED-JOBS.TITLE' | translate }}
      </div>
      <geaui-table-v2
        class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
        [tableId]="TABLE_ID"
        [columnDefinitions]="scheduledJobColumnService.columns"
        [data]="jobInfoList"
        [loading]="loading"
        [noDataText]="'SCHEDULED-JOBS-LIST.NO-RESULTS' | translate"
        [sortable]="true"
        [customNoData]="true"
        [rowsClickable]="false"
        [rowGroupMode]="'rowspan'"
        [highlightRow]="highlightRow"
        [disableHoverRowEffect]="true"
        uniquePropertyKey="controlId"
        selectionMode="single"
        groupRowsByColumn="jobName">
      </geaui-table-v2>
    </div>
  </ng-container>
</geaui-page-content-layout>
