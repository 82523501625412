import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderRendererComponent } from '@gea/digital-ui-lib';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'advance-checkbox-header-renderer',
  standalone: true,
  imports: [CommonModule, TableModule],
  template: '<p-tableHeaderCheckbox></p-tableHeaderCheckbox>',
})
export class CheckboxHeaderRendererComponent<T> implements HeaderRendererComponent<T> {}
