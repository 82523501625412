@if (isMobileView && showDocumentDownload && documentGroups) {
  <geaui-bottom-sheet
    class="document-download"
    [(visible)]="showDocumentDownload"
    [showBackButton]="!!selectedDocument"
    (backButtonClick)="selectedDocument = undefined"
    (visibleChange)="showDocumentDownloadChange.emit($event)">
    <ng-container header>
      <span class="gea-font-heading-20">{{ 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.TITLE' | translate }}</span>
    </ng-container>

    <div class="content">
      @if (!selectedDocument) {
        <ng-container *ngTemplateOutlet="documentTypeSelection"></ng-container>
      } @else if (selectedDocument) {
        <ng-container *ngTemplateOutlet="languageSelection; context: { documentType: selectedDocument }"></ng-container>
      }
    </div>
  </geaui-bottom-sheet>
}

<ng-template #documentTypeSelection>
  <div class="gea-font-body-16 content-title">{{ 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.SELECT-DOCUMENT' | translate }}</div>

  @for (documentGroup of documentGroups; track documentGroup.documentType) {
    <div class="border document-type" (click)="changeSelectedDocument(documentGroup)">
      <geaui-icon-v2 [isFileIcon]="true" color="none" file="32px_pdf"></geaui-icon-v2>
      <div class="gea-font-body-16 title">
        {{ 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.TABLE.DOCUMENT-TYPE.' + documentGroup?.documentType | translate }}
      </div>
    </div>
  }
</ng-template>

<ng-template #languageSelection let-documentType="documentType">
  <div class="gea-font-body-16 content-title">{{ 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.SELECT-LANGUAGE' | translate }}</div>

  @for (document of documentType.documents; track document.language) {
    <div class="border" (click)="downloadFile(document?.downloadLink)">
      <div class="gea-font-body-16">
        {{ document?.language }}
      </div>
    </div>
  }
</ng-template>
