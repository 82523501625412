/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductAttributeGroup } from './productAttributeGroup';


export interface ProductPassport { 
    registeredProductId: string;
    serialId: string;
    description: string;
    productName: string;
    nickname: string;
    manufacturer: string;
    yearOfConstruction: string;
    equipmentImageLink?: string;
    showLoginInfo: boolean;
    attributeGroups?: Array<ProductAttributeGroup>;
}

