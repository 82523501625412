/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface JobExecutionNotesInner { 
    id?: string;
    logLevel?: JobExecutionNotesInner.LogLevelEnum;
    message?: string;
    creationDateTime?: string;
}
export namespace JobExecutionNotesInner {
    export type LogLevelEnum = 'NotFound' | 'Info' | 'Warning' | 'Error';
    export const LogLevelEnum = {
        NOT_FOUND: 'NotFound' as LogLevelEnum,
        INFO: 'Info' as LogLevelEnum,
        WARNING: 'Warning' as LogLevelEnum,
        ERROR: 'Error' as LogLevelEnum
    };
}


