import { Inject, Injectable } from '@angular/core';
import { FeatureFlagApiService } from './feature-flag-api.service';
import { ApiServiceInterface } from '@gea/digital-ui-lib';
import { Observable } from 'rxjs';
import { FeaturesConfiguration } from '../models/features-configuration.model';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../environments/models/environment.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements ApiServiceInterface {
  apiVersion = '1';
  url = this.environment.featureFlag.baseURL ? '/feature-flags/' : 'feature-flag-service';

  constructor(
    private featureFlagApiService: FeatureFlagApiService,
    @Inject(ENVIRONMENT_CONFIG) private environment: EnvironmentConfiguration
  ) {}

  getFeatureFlagConfigurationForUser(): Observable<FeaturesConfiguration> {
    return this.featureFlagApiService.get<FeaturesConfiguration>(
      `${this.url}/v${this.apiVersion}/feature-flags/configuration/me`
    );
  }

  setUrl(url?: string) {
    this.url = url ?? this.url;
    return this;
  }

  setApiVersion(apiVersion?: string) {
    this.apiVersion = apiVersion ?? this.apiVersion;
    return this;
  }
}
