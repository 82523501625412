<div class="document-download-modal-container">
  <div class="gea-font-body-18 margin">{{ 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.BODY' | translate }}</div>
  <geaui-table-v2
    class="margin"
    [columnDefinitions]="documentsTableColumns"
    [rowsClickable]="false"
    [data]="tableData"
    [isStriped]="false"
    (selectionChange)="changeSelection($event)"
    selectionMode="multiple">
  </geaui-table-v2>
</div>
