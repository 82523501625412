import { Component, DestroyRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobService } from '../../../services/job.service';
import { JobExecution, JobInfoList } from '../../../api/v1';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScheduledJobsColumnService } from './services/scheduled-jobs-column.service';
import {
  ApiErrorResponse,
  ErrorHandlerV2Service,
  PageContentLayoutComponent,
  SideModalV2Module,
  TableV2Module,
} from '@gea/digital-ui-lib';
import { ControlJobRendererService } from './control-job-renderer/control-job-renderer.service';
import JobStatusEnum = JobExecution.JobStatusEnum;

export interface MappedJobInfo {
  jobName: string;
  executable?: boolean;
  status: JobExecution.JobStatusEnum;
  dateTime?: string;
  runningTime?: string;
  note?: string;
  controlId: string;
  expandable?: boolean;
  items?: MappedJobInfo[];
  oddStripingColor?: boolean;
}

@Component({
  selector: 'advance-scheduled-jobs',
  standalone: true,
  imports: [CommonModule, TranslateModule, TableV2Module, SideModalV2Module, PageContentLayoutComponent],
  templateUrl: './scheduled-jobs.component.html',
  styleUrl: './scheduled-jobs.component.scss',
})
export class ScheduledJobsComponent implements OnInit {
  readonly TABLE_ID = 'scheduled-job-list';

  loading = false;
  jobInfoList: MappedJobInfo[] = [];
  jobs: JobInfoList = { jobs: [] };
  highlightRow = '';
  allAvailableJobs: string[] = [];

  constructor(
    private jobService: JobService,
    private destroyed: DestroyRef,
    private errorHandlerService: ErrorHandlerV2Service,
    private controlJobRendererService: ControlJobRendererService,
    public scheduledJobColumnService: ScheduledJobsColumnService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.reloadJobData();

    setInterval(
      () => {
        this.reloadJobData();
      },
      1000 * 60 * 2
    ); // 1000ms * 60sec * 2min = 2min

    this.checkJobStartedAndReloadData();
    this.reloadDataOnRowExpand();
  }

  checkJobStartedAndReloadData() {
    this.jobService.jobStarted$.pipe(takeUntilDestroyed(this.destroyed)).subscribe((jobName) => {
      this.reloadJobData();

      this.jobService.jobDataReloaded$.pipe(takeUntilDestroyed(this.destroyed)).subscribe(() => {
        const startedJob = this.jobInfoList.find((job) => job.jobName === jobName && job.status === JobStatusEnum.STARTED);

        if (startedJob) {
          this.highlightRow = startedJob.controlId;
        }
      });
    });
  }

  reloadDataOnRowExpand() {
    this.controlJobRendererService.rowToggled.pipe(takeUntilDestroyed(this.destroyed)).subscribe(() => {
      this.receiveJobData(this.jobs);
    });
  }

  reloadJobData() {
    this.jobService
      .getJobs()
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe({
        next: this.receiveJobData.bind(this),
        error: this.receiveError.bind(this),
      });
  }

  receiveJobData(jobInfoList: JobInfoList) {
    this.jobInfoList = this.mapJobInfoList(jobInfoList);
    this.jobService.setJobDateReloaded();
    this.loading = false;
  }

  mapJobInfoList(jobInfoList: JobInfoList): MappedJobInfo[] {
    this.jobs = jobInfoList;

    return jobInfoList.jobs.flatMap((job, index) => {
      if (!this.allAvailableJobs.includes(job.name)) {
        this.allAvailableJobs.push(job.name);
      }

      const expandableItems = job.executions.slice(1).map((execution) => ({
        jobName: job.name,
        executable: false,
        status: execution.jobStatus,
        dateTime: execution.startDate,
        runningTime: this.calculateRunningTime(execution.startDate, execution.endDate, execution.jobStatus),
        note: execution.notes?.[0]?.message,
        controlId: execution.id,
        oddStripingColor: (index + 1) % 2 === 0,
        expandable: false,
      }));

      const firstExecution = job.executions[0];
      const mainItem = {
        jobName: job.name,
        executable: false,
        status: firstExecution.jobStatus,
        dateTime: firstExecution.startDate,
        runningTime: this.calculateRunningTime(firstExecution.startDate, firstExecution.endDate, firstExecution.jobStatus),
        note: firstExecution.notes?.[0]?.message,
        controlId: firstExecution.id,
        oddStripingColor: (index + 1) % 2 === 0,
        expandable: true,
        items: this.controlJobRendererService.currentOpenedRows.includes(job.name) ? [] : expandableItems,
      };

      return [
        {
          jobName: job.name,
          executable: job.executable,
          status: JobStatusEnum.PLANNED,
          dateTime: job.plannedStartDate,
          runningTime: undefined,
          oddStripingColor: (index + 1) % 2 === 0,
          note: undefined,
          controlId: 'notSet',
        },
        mainItem,
        ...(this.controlJobRendererService.currentOpenedRows.includes(job.name) ? expandableItems : []),
      ];
    });
  }

  calculateRunningTime(startDate: string, endDate: string, jobStatus: JobStatusEnum): string {
    if (jobStatus !== JobStatusEnum.SUCCESS && jobStatus !== JobStatusEnum.FAILED) {
      return '';
    }

    const diffInMs = new Date(endDate).getTime() - new Date(startDate).getTime();
    const hours = Math.floor(diffInMs / 3600000);
    const minutes = Math.floor((diffInMs % 3600000) / 60000);
    const seconds = Math.floor((diffInMs % 60000) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  receiveError(error: ApiErrorResponse) {
    this.errorHandlerService.handleError(error);
    this.loading = false;
  }
}
