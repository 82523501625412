<div class="geaui-grid detail-container" [ngClass]="{ 'top-padding': !isLoggedIn, 'geaui-sidebar-grid': isLoggedIn }">
  <div
    class="back-navigation geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
    *ngIf="isLoggedIn">
    <geaui-link-v2 [loading]="loading" routerLink="/" preIcon="16px_arrow-left" data-cy="detail-back-navigation">
      {{ 'ASSET-DETAIL.NAVIGATE-TO-OVERVIEW' | translate }}
    </geaui-link-v2>
  </div>

  <div
    class="product-information geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
    [ngClass]="{ sticky: productInformationIsSticky }">
    <div [ngClass]="productInformationIsSticky ? 'gea-font-heading-14' : 'gea-font-heading-24'">
      <div class="product-title" *ngIf="!loading; else skeletonTitle" data-cy="title">
        {{ product?.description ?? '-' }}
      </div>
    </div>

    <div class="inline">
      <span
        *ngIf="!loading; else skeletonSubTitle"
        [ngClass]="productInformationIsSticky ? 'gea-font-body-14' : 'gea-font-body-16'"
        data-cy="sub-title">
        {{ 'ASSET-DETAIL.SERIAL-NR' | translate }} {{ product?.serialId ?? '-' }}
      </span>
    </div>
  </div>

  <advance-status-info
    class="geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4"
    [showStatus]="!isMobileView"
    [product]="product"
    [loading]="loading"
    (showStatusChange)="isMobileView = $event">
  </advance-status-info>

  <div
    class="link-buttons geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4">
    <geaui-button-v2
      [disabled]="!hasPublicDocuments"
      [flex]="isMobileView"
      [loading]="loading"
      (click)="showDocumentDownload = true"
      size="normal"
      type="outlined-link"
      preIcon="16px_move-layer-down"
      data-cy="download-manual">
      {{ 'ASSET-DETAIL.GENERAL-DOCUMENTS' | translate }}
    </geaui-button-v2>

    <geaui-button-v2
      *ngIf="hasDocumentAppAccess"
      [disabled]="true"
      [flex]="isMobileView"
      [postIcon]="true"
      [loading]="loading"
      (click)="openDocumentApp()"
      size="normal"
      type="outlined-link"
      preIcon="16px_folder"
      data-cy="open-document-app">
      {{ 'ASSET-DETAIL.DOCUMENTATION' | translate }}
    </geaui-button-v2>

    <geaui-button-v2
      *ngIf="hasSupportAppAccess"
      [disabled]="!isLoggedIn || (isLoggedIn && !product?.registeredProductId)"
      [flex]="isMobileView"
      [postIcon]="true"
      [loading]="loading"
      (click)="openSupportApp()"
      size="normal"
      type="outlined-link"
      preIcon="16px_a-chat"
      data-cy="open-support-app">
      {{ 'ASSET-DETAIL.SUPPORT' | translate }}
    </geaui-button-v2>
  </div>

  <div
    class="accordion geaui-col-desktop-xl-start-7 geaui-col-desktop-xl-12 geaui-col-desktop-lg-12 geaui-col-desktop-md-12 geaui-col-tablet-lg-12 geaui-col-tablet-sm-12 geaui-col-phone-lg-8 geaui-col-phone-sm-4">
    <advance-detail-accordion [loading]="loading" [product]="product" [isLoggedIn]="isLoggedIn"> </advance-detail-accordion>
  </div>
</div>

<advance-floating-action-button
  [show]="showBackToTopButton"
  (buttonClicked)="scrollToTop()"
  file="24px_arrow-line-up"
  data-cy="scroll-to-top">
</advance-floating-action-button>

@if (showDocumentDownload) {
  <advance-document-download
    [isMobileView]="isMobileView"
    [documentGroups]="publicDocuments"
    [showDocumentDownload]="showDocumentDownload"
    (showDocumentDownloadChange)="this.showDocumentDownload = $event">
  </advance-document-download>
}

<ng-template #skeletonTitle>
  <ngx-skeleton-loader [theme]="{ height: '2rem', width: '15rem' }" count="1" appearance="line"></ngx-skeleton-loader>
</ng-template>

<ng-template #skeletonSubTitle>
  <ngx-skeleton-loader
    [theme]="{ height: '1.25rem', width: '10rem', 'margin-right': '1.25rem' }"
    count="1"
    appearance="line"></ngx-skeleton-loader>
</ng-template>
