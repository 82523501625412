import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { IsMobileViewService } from '../../../services/is-mobile-view.service';
import { ProductPassport } from '../../../api/v1';

@Component({
  selector: 'advance-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
})
export class StatusInfoComponent implements OnDestroy {
  readonly FALLBACK_IMAGE_SRC = '/assets/images/no-image.png';
  _product!: ProductPassport;
  productImageLink = this.FALLBACK_IMAGE_SRC;
  productStatus: { translationKey: string; property: string; value?: string }[] = [
    { translationKey: 'PRODUCTION-DESCRIPTION', property: 'productName' },
    { translationKey: 'NICKNAME', property: 'nickname' },
    { translationKey: 'MANUFACTURER', property: 'manufacturer' },
    { translationKey: 'YEAR-OF-CONSTRUCTION', property: 'yearOfConstruction' },
  ];

  @Input() set product(product: ProductPassport) {
    this._product = product;
    this.updateProductImage();
    this.updateProductStatus();
  }
  @Input() loading = false;
  @Input() showStatus = false;
  @Output() showStatusChange = new EventEmitter<boolean>();

  isMobileView$: Observable<boolean>;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public isMobileViewService: IsMobileViewService) {
    this.isMobileView$ = isMobileViewService.isMobileView$;
  }

  onShowStatusChange(showStatus: boolean): void {
    this.showStatus = showStatus;
    this.showStatusChange.emit(this.showStatus);
  }

  updateProductImage(): void {
    this.productImageLink = this._product?.equipmentImageLink || this.FALLBACK_IMAGE_SRC;
  }

  updateProductStatus(): void {
    this.productStatus.forEach((status) => {
      const propertyValue = this._product?.[status.property as keyof typeof this._product] as string;
      if (propertyValue) {
        status.value = propertyValue;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
