import { Component } from '@angular/core';

import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { Product } from '../../../api/v1';

@Component({
  selector: 'advance-filter-rules-renderer',
  styleUrls: ['./filter-rules-renderer.component.scss'],
  templateUrl: './filter-rules-renderer.component.html',
})
export class FilterRulesRendererComponent implements ColumnRendererComponent<Product> {
  rowData?: Product;
}
