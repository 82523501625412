<div class="container" *ngIf="rowData">
  <geaui-button-v2
    [postIcon]="true"
    [disabled]="!rowData?.executable"
    [sending]="sendingStartRequest"
    (click)="startJob(rowData?.jobName)"
    size="small">
    {{ 'SCHEDULED-JOBS-LIST.START-BUTTON' | translate }}
  </geaui-button-v2>

  <geaui-icon-v2
    class="row-toggle"
    *ngIf="rowData?.items"
    [file]="
      controlJobRendererService.currentOpenedRows.includes(rowData.jobName) && rowData.expandable
        ? '16px_chevron-up'
        : '16px_chevron-down'
    "
    (click)="controlJobRendererService.checkJobItem(rowData.jobName)"
    color="black">
  </geaui-icon-v2>
</div>
