/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobExecution } from './jobExecution';


export interface JobInfo { 
    name: string;
    plannedStartDate: string;
    executable: boolean;
    executions: Array<JobExecution>;
}

