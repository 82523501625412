import { Injectable, OnDestroy } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ReplaySubject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IsMobileViewService implements OnDestroy {
  readonly MOBILE_BREAKPOINT = '(max-width: 767px)';

  isMobileView$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([this.MOBILE_BREAKPOINT])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        this.isMobileView$.next(state.breakpoints[this.MOBILE_BREAKPOINT]);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
