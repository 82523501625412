import { Injectable } from '@angular/core';
import {
  ChipRendererComponent,
  ColumnDefinition,
  ColumnRendererComponent,
  DateRendererComponent,
  ReadOnlyChipType,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { ControlJobRendererComponent } from '../control-job-renderer/control-job-renderer.component';
import { NoteRendererComponent } from '../note-renderer/note-renderer.component';

interface ChipConfigMapping {
  severity: ReadOnlyChipType;
  text: string;
  preIcon: string;
}

interface ChipConfig {
  [key: string]: ChipConfigMapping;
}

@Injectable({
  providedIn: 'root',
})
export class ScheduledJobsColumnService {
  readonly chipMapping: ChipConfig = {
    Planned: { severity: 'default', text: 'SCHEDULED-JOB-STATUS.Planned', preIcon: '16px_time' },
    Started: { severity: 'information', text: 'SCHEDULED-JOB-STATUS.Started', preIcon: '16px_arrow-e' },
    Success: { severity: 'success', text: 'SCHEDULED-JOB-STATUS.Success', preIcon: '16px_check-small' },
    Aborted: { severity: 'warning', text: 'SCHEDULED-JOB-STATUS.Aborted', preIcon: '16px_warning' },
    Failed: { severity: 'error', text: 'SCHEDULED-JOB-STATUS.Failed', preIcon: '16px_error' },
  };

  columns = [
    this.createTranslatedColumn('SCHEDULED-JOBS-LIST.JOB', 'jobName', 'SCHEDULED-JOB.VALUE.', true),
    this.createChipRendererColumn('SCHEDULED-JOBS-LIST.STATUS', 'status'),
    {
      key: 'dateTime',
      displayName: 'SCHEDULED-JOBS-LIST.DATE-TIME',
      sortable: false,
      renderer: {
        component: DateRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          format: 'short',
        },
      },
      width: 250,
    },
    {
      key: 'runningTime',
      displayName: 'SCHEDULED-JOBS-LIST.RUNNING-TIME',
      sortable: false,
      width: 200,
    },
    {
      key: 'note',
      displayName: 'SCHEDULED-JOBS-LIST.NOTE',
      sortable: false,
      renderer: {
        component: NoteRendererComponent as ColumnRendererComponent<unknown>,
      },
      width: 150,
    },
    {
      key: 'controlId',
      displayName: 'SCHEDULED-JOBS-LIST.CONTROL',
      renderer: {
        component: ControlJobRendererComponent as ColumnRendererComponent<unknown>,
      },
      sortable: false,
      width: 300,
    },
  ];

  createTranslatedColumn(
    displayName: string,
    key: string,
    translationPrefix: string,
    rowspan = false,
    width = 350
  ): ColumnDefinition {
    return {
      displayName,
      key,
      rowspan,
      sortable: false,
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          prefix: translationPrefix,
        },
      },
      width,
    };
  }

  createChipRendererColumn(displayName: string, key: string) {
    return {
      displayName,
      key,
      sortable: false,
      renderer: {
        component: ChipRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          mapping: this.chipMapping,
        },
      },
    };
  }
}
