/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type VisibilityType = 'Public' | 'Private' | 'Unknown' | 'Mixed';

export const VisibilityType = {
    PUBLIC: 'Public' as VisibilityType,
    PRIVATE: 'Private' as VisibilityType,
    UNKNOWN: 'Unknown' as VisibilityType,
    MIXED: 'Mixed' as VisibilityType
};

