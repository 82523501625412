/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type RegisteredProductCategory = 'NOT_FOUND' | 'ACCESSORIES_A' | 'INSTALLATION_POINT_I' | 'CUSTOMER_EQUIPMENT_S';

export const RegisteredProductCategory = {
    NOT_FOUND: 'NOT_FOUND' as RegisteredProductCategory,
    ACCESSORIES: 'ACCESSORIES_A' as RegisteredProductCategory,
    INSTALLATION_POINT: 'INSTALLATION_POINT_I' as RegisteredProductCategory,
    CUSTOMER_EQUIPMENT: 'CUSTOMER_EQUIPMENT_S' as RegisteredProductCategory
};

