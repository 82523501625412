<ng-template [ngIf]="rowData?.excludedByFilter" [ngIfElse]="notExcludedByFilter">
  <span class="badge-container badge-failed" [pTooltip]="failedTooltip">
    {{ 'ASSET-LIST.EXCLUDED-BY-FILTER.FAILED' | translate }}
  </span>
</ng-template>

<ng-template #notExcludedByFilter>
  <span class="badge-container badge-passed">
    {{ 'ASSET-LIST.EXCLUDED-BY-FILTER.PASSED' | translate }}
  </span>
</ng-template>

<ng-template #failedTooltip>
  <h4>{{ 'ASSET-LIST.EXCLUDED-BY-FILTER.FAILED.FILTER.HEADER' | translate }}</h4>
  <ul>
    <li *ngFor="let ruleCode of rowData?.excludedByRuleCodes ?? []">
      {{ 'ASSET-LIST.EXCLUDED-BY-FILTER.FAILED.FILTER.' + ruleCode | translate }}
    </li>
  </ul>
</ng-template>
