import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private msalService: MsalService) {}

  private getTokenClaims(): { [key: string]: string } {
    return this.msalService.instance.getActiveAccount()?.idTokenClaims as { [key: string]: string };
  }

  public isTempUser(): boolean {
    const claims = this.getTokenClaims() ?? [];
    return claims['extension_user_context'] === 'TEMP_MEMBERSHIP';
  }
}
