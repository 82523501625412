import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnRendererComponent } from '@gea/digital-ui-lib';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'advance-checkbox-renderer',
  standalone: true,
  imports: [CommonModule, TableModule],
  template: '<p-tableCheckbox [value]="rowData"></p-tableCheckbox>',
})
export class CheckboxRendererComponent<T> implements ColumnRendererComponent<T> {
  rowData?: T;
}
