import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { MsalGuard } from '@azure/msal-angular';

import { AssetsComponent } from './components/assets/assets.component';
import { DetailComponent } from './components/detail/detail.component';
import { ScheduledJobsComponent } from './components/admin/scheduled-jobs/scheduled-jobs.component';

const routes: Routes = [
  {
    path: 'assets',
    component: AssetsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'id/:geaPUID',
    component: DetailComponent,
  },
  {
    path: 'assets/:geaPUID',
    component: DetailComponent,
  },
  {
    path: 'admin/scheduled-jobs',
    component: ScheduledJobsComponent,
    // TODO add feature flag guard read feature flags from store CE-5847 once CE-5700 is done
    canActivate: [MsalGuard],
  },
  {
    path: '',
    redirectTo: '/assets',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/assets',
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    TranslateModule,
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
