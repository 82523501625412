/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentType } from './documentType';
import { Document } from './document';


export interface DocumentGroup { 
    documentType?: DocumentType;
    documents?: Array<Document>;
}
export namespace DocumentGroup {
}


