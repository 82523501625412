/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductAttributeMappingType = 'NotFound' | 'Text' | 'Translation' | 'Date';

export const ProductAttributeMappingType = {
    NOT_FOUND: 'NotFound' as ProductAttributeMappingType,
    TEXT: 'Text' as ProductAttributeMappingType,
    TRANSLATION: 'Translation' as ProductAttributeMappingType,
    DATE: 'Date' as ProductAttributeMappingType
};

