import { NgModule } from '@angular/core';
import { FloatingActionButtonComponent } from './floating-action-button.component';
import { IconModule } from '@gea/digital-ui-lib';

@NgModule({
  declarations: [FloatingActionButtonComponent],
  imports: [IconModule],
  providers: [],
  bootstrap: [],
  exports: [FloatingActionButtonComponent],
})
export class FloatingActionButtonModule {}
