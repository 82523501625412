/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SystemStatus } from './systemStatus';
import { ServiceStatus } from './serviceStatus';
import { RegisteredProductCategory } from './registeredProductCategory';
import { UserStatus } from './userStatus';
import { ProductAttributeGroup } from './productAttributeGroup';


export interface Product { 
    id: string;
    sourceSystemId?: string;
    organization?: string;
    organizationId?: string;
    organizationName?: string;
    mdgCustomerNumber?: string;
    serialId?: string;
    salesOrderOrProjectId?: string;
    itemsOrTagId?: string;
    registeredProductDescription: string;
    upperId?: string;
    productId: string;
    customerEquipmentDescription?: string;
    userStatus?: UserStatus;
    serviceStatus?: ServiceStatus;
    businessClassificationProdL1: string;
    businessClassificationProdL2: string;
    yearOfConstruction?: string;
    attributeGroups?: Array<ProductAttributeGroup>;
    active: boolean;
    excludedByFilter?: boolean;
    excludedByRuleCodes?: Array<string>;
    objectId: string;
    geaPUID?: string;
    registeredProductCategory?: RegisteredProductCategory;
    machineTypeLong?: string;
    systemStatus?: SystemStatus;
    warrantyId?: string;
    installedBaseId?: string;
    validFrom?: string;
    validTo?: string;
    referenceDate?: string;
    commissioningDate?: string;
    handoverDate?: string;
    warrantyStartDate?: string;
    warrantyEndDate?: string;
    creationDateTime?: string;
    lastChangeDateTime?: string;
    manufacturer?: string;
    modelNumber?: string;
    manufacturerPartNo?: string;
    manufacturerSerialNo?: string;
    equipmentImageLink?: string;
    displayName: string;
    serialProjectId: string;
    machineNickname: string;
}
export namespace Product {
}


