import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@gea/digital-ui-lib';
import { MsalBroadcastService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagApiService extends ApiService {
  constructor(
    @Inject('featureFlagBaseUrl') protected override baseUrl: string,
    protected override msalBroadcast: MsalBroadcastService,
    protected override http: HttpClient,
    protected override store: Store
  ) {
    super(baseUrl, undefined, msalBroadcast, http, store);
  }

  setBaseUrl(baseUrl?: string) {
    this.baseUrl = baseUrl ?? this.baseUrl;
  }
}
