/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ServiceStatus = 'NOT_FOUND' | 'NOT_SERVICED_Z01' | 'SERVICE_BY_CUSTOMER_Z02' | 'SERVICE_BY_GEA_Z03' | 'SERVICE_BY_OTHER_Z04' | 'UNKNOWN_Z05' | 'SERVICE_BY_PARTNER_Z06';

export const ServiceStatus = {
    NOT_FOUND: 'NOT_FOUND' as ServiceStatus,
    NOT_SERVICED: 'NOT_SERVICED_Z01' as ServiceStatus,
    SERVICE_BY_CUSTOMER: 'SERVICE_BY_CUSTOMER_Z02' as ServiceStatus,
    SERVICE_BY_GEA: 'SERVICE_BY_GEA_Z03' as ServiceStatus,
    SERVICE_BY_OTHER: 'SERVICE_BY_OTHER_Z04' as ServiceStatus,
    UNKNOWN: 'UNKNOWN_Z05' as ServiceStatus,
    SERVICE_BY_PARTNER: 'SERVICE_BY_PARTNER_Z06' as ServiceStatus
};

