import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule } from '@angular/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';

import { MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { AccordionModule } from 'primeng/accordion';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { NgxsModule } from '@ngxs/store';
import {
  ActionRequiredState,
  AppEnvironmentState,
  BannerModule,
  BottomSheetModule,
  ButtonGroupV2Module,
  ButtonV2Module,
  ChipModule,
  GeaTranslateHttpLoader,
  HeaderModule,
  IconModule,
  LinkV2Module,
  SearchBarV2Module,
  TableV2Module,
  ToggleV2Module,
  TooltipV2Module,
  UserState,
  HeaderConfig,
  PageLayoutModule,
  SidebarV2Module,
  ComplexDialogV2Module,
  SharedModule,
  GeaMissingTranslationHandler,
  PermissionsState,
} from '@gea/digital-ui-lib';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AssetsComponent } from './components/assets/assets.component';
import { DetailComponent } from './components/detail/detail.component';
import { StatusInfoComponent } from './components/detail/status-info/status-info.component';
import { FloatingActionButtonModule } from './components/detail/floating-action-button/floating-action-button.module';
import { Configuration, ProductsApiService, ProductselectionApiService, PublicApiService } from './api/v1';
import { NgIf } from '@angular/common';
import { FilterRulesRendererComponent } from './components/assets/filter-rules-renderer/filter-rules-renderer.component';
import { TooltipModule } from 'primeng/tooltip';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { DetailAccordionComponent } from './components/detail/detail-accordion/detail-accordion.component';
import { DocumentDownloadComponent } from './components/detail/document-download/document-download.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

export function HttpLoaderFactory(http: HttpClient, environment: EnvironmentConfiguration) {
  return new GeaTranslateHttpLoader(http, environment.localizationStorageURL, ['i18n/1/asset/']);
}

export const HEADER_CONFIG = new InjectionToken<HeaderConfig>('HeaderConfig');

@NgModule({
  declarations: [AppComponent, AssetsComponent, DetailComponent, StatusInfoComponent, FilterRulesRendererComponent],
  imports: [
    NgxsModule.forRoot([UserState, ActionRequiredState, AppEnvironmentState, PermissionsState], {
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en-US',
      useDefaultLang: false,
      loader: {
        deps: [HttpClient, ENVIRONMENT_CONFIG],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: GeaMissingTranslationHandler },
    }),
    HeaderModule.forRoot(HEADER_CONFIG),
    TableV2Module,
    ButtonV2Module,
    ButtonGroupV2Module,
    AccordionModule,
    LinkV2Module,
    BannerModule,
    ChipModule,
    MessagesModule,
    MessageModule,
    BottomSheetModule,
    PageLayoutModule,
    SidebarV2Module,
    TooltipV2Module,
    NgxSkeletonLoaderModule,
    FloatingActionButtonModule,
    IconModule,
    SearchBarV2Module,
    ToggleV2Module,
    NgIf,
    TooltipModule,
    ComplexDialogV2Module,
    SharedModule,
    DetailAccordionComponent,
    DocumentDownloadComponent,
    ProgressSpinnerModule,
  ],
  providers: [
    provideHttpClient(),
    MsalService,
    TranslatePipe,
    {
      provide: 'featureFlagBaseUrl',
      useFactory: (env: EnvironmentConfiguration) => env.featureFlag.baseURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'baseUrl',
      useFactory: (env: EnvironmentConfiguration) => env.portal.baseURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'subscriptionKey',
      useValue: '',
    },
    {
      provide: 'iconPath',
      useFactory: (env: EnvironmentConfiguration) => env.iconStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'appContentPath',
      useFactory: (env: EnvironmentConfiguration) => env.appContentStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'storageURL',
      useFactory: (env: EnvironmentConfiguration) => env.localizationStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    PublicApiService,
    ProductsApiService,
    ProductselectionApiService,
    {
      provide: Configuration,
      useFactory: (env: EnvironmentConfiguration) =>
        new Configuration({
          basePath: (env.advance.baseURL ?? env.baseURL) + 'asset/v1',
        }),
      deps: [ENVIRONMENT_CONFIG],
      multi: false,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
