import { Component, DestroyRef, HostListener, Inject, OnInit } from '@angular/core';

import {
  AboutPanelComponent,
  AboutPanelInputs,
  ApiErrorResponse,
  AuthState,
  ErrorHandlerV2Service,
  FeatureFlagService,
  NavigationItem,
  SetEnvironment,
  SidePanel,
  SidepanelTypes,
  UserState,
  Environment as EnvironmentEnum
} from '@gea/digital-ui-lib';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';

import { ActivationEnd, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { Meta } from '@angular/platform-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'advance-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly DNP_VIEW_ROUTES: string[] = ['id/:geaPUID', 'assets/:geaPUID'];
  readonly ASSET_APP_TITLE = 'ASSET-APP.TITLE';
  readonly DNP_VIEW_TITLE = 'ASSET-APP.DNP-VIEW.TITLE';

  tokenReady = false;
  loggedOutPageReady = false;
  userReady = false;
  noAuth?: boolean;
  currentLanguage = '';
  userCountry = 'US';
  headerTitle = 'Asset App';

  navItems: NavigationItem[] = [
    {
      label: 'UI-LIB.GENERAL.HOME',
      route: '/assets',
      icon: '16px_apps',
    },
  ];

  adminNavItems: NavigationItem[] = [];

  botItems: SidePanel[] = [];
  private mobileMetaTagSet?: boolean;
  readonly NO_AUTH_ROUTES: string[] = ['id/:geaPUID', 'assets/:geaPUID'];

  constructor(
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private meta: Meta,
    private destroyed: DestroyRef,
    private featureFlagService: FeatureFlagService,
    private errorHandlerService: ErrorHandlerV2Service,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration
  ) {
    this.store
      .select(UserState.loggedOutPageReady)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((ready) => {
        this.loggedOutPageReady = !!ready;
      });
    this.store
      .select(UserState.authState)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((authState) => {
        if (authState === AuthState.LogoutRequested) {
          this.tokenReady = false;
          this.loggedOutPageReady = false;
        }
      });
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyed),
        filter((event) => event instanceof ActivationEnd)
      )
      .subscribe((event) => {
        this.noAuth = this.NO_AUTH_ROUTES.includes(event.snapshot?.routeConfig?.path || '');
      });

    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyed),
        filter((event) => event instanceof ActivationEnd)
      )
      .subscribe((event) => {
        if (event instanceof ActivationEnd) {
          this.headerTitle = this.getHeaderTitle(event.snapshot?.routeConfig?.path ?? '');
        }
      });

    this.store
      .select(UserState.authState)
      .pipe(
        takeUntilDestroyed(this.destroyed),
        map((authState) => authState === AuthState.LoggedIn)
      )
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          this.getFeatureFlags();
        }
      });
  }

  getFeatureFlags() {
    this.featureFlagService
      .getFeatureFlagConfigurationForUser()
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe({
        next: (featuresConfiguration) => {
          const features = featuresConfiguration.features;
          const hasAdminViewFeatureFlag = 'core_asset.adminView.enabled' in features;
          if (hasAdminViewFeatureFlag) {
            this.adminNavItems = [
              {
                label: 'ASSET-APP.ADMIN-VIEW.SCHEDULED-JOBS',
                route: '/admin/scheduled-jobs',
                icon: '16px_configuration-tools',
              },
            ];
          }
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMetaTag();
  }

  ngOnInit(): void {
    this.setMetaTag();
    this.setContentSecurityMetaTag()

    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store
      .select(UserState.user)
      .pipe(
        takeUntilDestroyed(this.destroyed),
        filter((user) => !!user.email)
      )
      .subscribe((user) => {
        this.tokenReady = true;

        if (user?.country) {
          this.userCountry = user.country;
        }
        const selectedLanguage = localStorage.getItem('language');
        if (user?.language) {
          this.translate.use(user.language);
        } else if (selectedLanguage) {
          this.translate.use(selectedLanguage);
        } else {
          this.translate.use(this.translate.defaultLang);
        }
        this.userReady = true;
      });

    if (this.environment.name) {
      this.store.dispatch(new SetEnvironment(this.environment.name));
    }
    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyed)).subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
      this.botItems = [
        {
          key: 'contact',
          icon: '16px_chat',
          label: 'UI-LIB.NAV-BAR.CONTACT',
          type: SidepanelTypes.CONTACT,
        },
        {
          key: 'about',
          icon: '16px_c-info',
          label: 'UI-LIB.NAV-BAR.ABOUT',
          type: SidepanelTypes.FOOTER,
          ref: AboutPanelComponent,
          inputs: {
            copyright: 'GEA Group Services GmbH 2023',
            footers: [
              {
                key: 'imprint-header',
                header: 'UI-LIB.FOOTER.IMPRINT',
                content: [
                  {
                    key: 'imprint',
                    icon: '24px_pdf',
                    label: 'UI-LIB.FOOTER.IMPRINT',
                    action: this.openImprint.bind(this),
                  },
                ],
              },
              {
                key: 'privacy-header',
                header: 'UI-LIB.FOOTER.DATAPRIVACY',
                content: [
                  {
                    key: 'privacy',
                    icon: '24px_pdf',
                    label: 'UI-LIB.FOOTER.DATAPRIVACY',
                    action: this.openDataprivacy.bind(this),
                  },
                ],
              },
              {
                key: 'terms-header',
                header: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                content: [
                  {
                    key: 'terms',
                    icon: '24px_pdf',
                    label: 'UI-LIB.FOOTER.TERMS-AND-CONDITIONS',
                    action: this.openTermsAndConditions.bind(this),
                  },
                ],
              },
              {
                key: 'cookie-settings',
                header: 'UI-LIB.FOOTER.COOKIE-SETTINGS',
                content: [
                  {
                    key: 'cookies',
                    label: 'UI-LIB.FOOTER.COOKIES',
                    action: this.openCookieSettings.bind(this),
                  },
                ],
              },
            ],
          } as AboutPanelInputs,
        },
      ] as SidePanel[];
    });
    this.translate.setDefaultLang('en-US');
    this.addCookielawScriptTag();
  }

  addCookielawScriptTag() {
    const cookielawId = this.environment.cookielawId;
    if (cookielawId) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', cookielawId);
      const head = document.head;
      const firstScriptTag = head.querySelector('script');
      if (firstScriptTag) {
        head.insertBefore(script, firstScriptTag);
      } else {
        head.appendChild(script);
      }
    }
  }

  getHeaderTitle(currentPath: string) {
    if (this.DNP_VIEW_ROUTES.includes(currentPath)) {
      return this.DNP_VIEW_TITLE;
    } else {
      return this.ASSET_APP_TITLE;
    }
  }

  openDataprivacy() {
    window.open(
      this.environment.storageURL +
        'terms/1/' +
        this.userCountry +
        '/' +
        this.currentLanguage.split('-')[0].toUpperCase() +
        '/DataPrivacy.pdf',
      '_blank'
    );
  }

  openTermsAndConditions() {
    window.open(
      this.environment.storageURL +
        'terms/1/' +
        this.userCountry +
        '/' +
        this.currentLanguage.split('-')[0].toUpperCase() +
        '/TermsAndConditions.pdf',
      '_blank'
    );
  }

  openImprint() {
    window.open('../../assets/documents/Imprint' + this.currentLanguage + '.pdf', '_blank');
  }

  openCookieSettings() {
    // this syntax is necessary here to access onetrust
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.OneTrust.ToggleInfoDisplay();
  }

  setMetaTag() {
    if (screen.width < 767 && !this.noAuth) {
      if (this.mobileMetaTagSet === undefined || !this.mobileMetaTagSet) {
        this.meta.removeTag('name="viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=1000' });
        this.mobileMetaTagSet = true;
      }
    } else {
      if (this.mobileMetaTagSet === undefined || this.mobileMetaTagSet) {
        this.meta.removeTag('name="viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
        this.mobileMetaTagSet = false;
      }
    }
  }

  private setContentSecurityMetaTag() {
    if(!this.environment.name) return

    const clarityHash = "'sha256-eBrsYnxCaUBTeM1XIhPkiIoPd5K7IfZTSVprFYMvtc4='"
    const gtm_hash = "'sha256-smAb3YviQyMN5UZODsgCQGZEF5muenzX2n483kTSE7I='"
    const storageUrls: { [key: string]: string } = {
      "dev": "https://content.apps.dev.gea.com/",
      "test": "https://content.apps.tst.gea.com/",
      "uat": "https://content.apps.uat.gea.com/",
      "prod": "https://content.apps.gea.com/",
    }
    const stage = this.environment.name === EnvironmentEnum.LOCAL ? 'dev' : this.environment.name
    const allowedScriptSources = ` 
    ${clarityHash} ${gtm_hash}  
      https://cdn.cookielaw.org https://www.googletagmanager.com https://www.google-analytics.com https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/legacy/build/pdf.worker.min.js  
      https://www.clarity.ms ${storageUrls[stage]} https://strgaccassets${stage}.blob.core.windows.net/strg-container-assets-${stage}/clarity-loader.js 
    `
    this.meta.addTag({
      'http-equiv': 'Content-Security-Policy',
      content: `script-src 'self' ${allowedScriptSources}; worker-src blob:;`,
    });
    this.meta.addTag({
      'http-equiv': 'Cache-control',
      content: `private, max-age=1800`,
    });
  }
}
