import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ColumnDefinition,
  ColumnRendererComponent,
  COMPLEX_DIALOG_INPUT_DATA,
  ComplexDialogEmbeddedView,
  HeaderRendererComponent,
  SelectOption,
  TableV2Module,
  TranslationRendererComponent,
} from '@gea/digital-ui-lib';
import { DocumentGroup } from '../../../../api/v1';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxRendererComponent } from './checkbox-renderer/checkbox-renderer.component';
import { CheckboxHeaderRendererComponent } from './checkbox-header-renderer/checkbox-header-renderer.component';
import { DocumentLanguageRendererComponent } from './document-language-renderer/document-language-renderer.component';

export interface DocumentDownloadDialogData {
  documentType?: string;
  languages: SelectOption[];
  selectedLanguage?: SelectOption;
}

@Component({
  selector: 'advance-document-download-dialog',
  standalone: true,
  imports: [CommonModule, TranslateModule, TableV2Module],
  templateUrl: './document-download-dialog.component.html',
  styleUrl: './document-download-dialog.component.scss',
})
export class DocumentDownloadDialogComponent implements ComplexDialogEmbeddedView {
  tableData: DocumentDownloadDialogData[] = [];
  currentlySelectedDocuments: DocumentDownloadDialogData[] = [];
  documentsTableColumns: ColumnDefinition[] = [
    {
      displayName: '',
      key: 'select',
      renderer: {
        component: CheckboxRendererComponent as ColumnRendererComponent<DocumentDownloadDialogData>,
      },
      headerRenderer: {
        component: CheckboxHeaderRendererComponent as HeaderRendererComponent<DocumentDownloadDialogData>,
      },
      width: 56,
    },
    {
      displayName: 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.TABLE.DOCUMENT-TYPE',
      key: 'documentType',
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          prefix: 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.TABLE.DOCUMENT-TYPE.',
        },
      },
      width: 100,
    },
    {
      displayName: 'ASSET-DETAIL.DOCUMENT-DOWNLOAD.TABLE.DOCUMENT-LANGUAGE',
      key: 'selectedLanguage',
      renderer: {
        component: DocumentLanguageRendererComponent as ColumnRendererComponent<SelectOption[]>,
      },
      width: 20,
    },
  ];

  constructor(@Inject(COMPLEX_DIALOG_INPUT_DATA) public publicDocuments?: DocumentGroup[]) {
    this.tableData =
      publicDocuments?.map((documentGroup) => ({
        documentType: documentGroup.documentType,
        languages:
          documentGroup.documents?.map((document) => ({
            name: document.language,
            value: document.downloadLink,
          })) ?? [],
      })) ?? [];
  }

  onAcceptClick() {
    this.currentlySelectedDocuments.forEach((document) => {
      const documentDownloadLink = document.selectedLanguage?.value as string;
      window.open(documentDownloadLink, '_blank');
    });
  }

  changeSelection(selectedDocuments: DocumentDownloadDialogData[]) {
    this.currentlySelectedDocuments = selectedDocuments;
  }
}
