import { Injectable } from '@angular/core';
import { RegisteredProductCategory, ServiceStatus, SystemStatus, UserStatus } from '../api/v1';
import {
  ColumnDefinition,
  ColumnRendererComponent,
  HeaderRendererComponent,
  RendererUtilService,
  TextFilterComponent,
  TranslationRendererComponent,
  VisibilitySettingsRendererComponent,
} from '@gea/digital-ui-lib';
import { TokenService } from './token.service';
import { FilterRulesRendererComponent } from '../components/assets/filter-rules-renderer/filter-rules-renderer.component';

@Injectable({
  providedIn: 'root',
})
export class AssetTableColumnService {
  readonly FLAT_TABLE_ID: string = 'gea-advance-asset-list';
  readonly HIERARCHY_TABLE_ID: string = 'gea-advance-asset-list-hierarchy';
  readonly UserStatus = UserStatus;
  readonly USER_STATUS_PREFIX = 'ASSET-LIST.USER-STATUS.';
  readonly ServiceStatus = ServiceStatus;
  readonly SERVICE_STATUS_PREFIX = 'ASSET-LIST.SERVICE-STATUS.';
  readonly RegisteredProductCategory = RegisteredProductCategory;
  readonly REGISTERED_PRODUCT_CATEGORY_PREFIX = 'ASSET-LIST.REGISTERED-PRODUCT-CATEGORY.';
  readonly SystemStatus = SystemStatus;
  readonly SYSTEM_STATUS_PREFIX = 'ASSET-LIST.SYSTEM-STATUS.';

  featureFlagDisabledColumns: ColumnDefinition[] = [
    this.createSortableTextFilterColumn('ASSET-LIST.SERIAL-PROJECT-ID', 'serialProjectId', 300),
    this.createSortableTextFilterColumn('ASSET-LIST.ORGANIZATION', 'organization', 300),
    this.createSortableTextFilterColumn('ASSET-LIST.MACHINE-NICKNAME', 'machineNickname', 300),
    this.createTranslatedColumn('ASSET-LIST.MACHINE-TYPE', 'productId', 'ASSET-DETAIL.ATTRIBUTE.productName.VALUE.'),
    this.createSortableTextFilterColumn('ASSET-LIST.CONSTRUCTION-YEAR', 'yearOfConstruction'),
  ];

  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  featureFlagEnabledColumns: ColumnDefinition[] = [
    ...this.featureFlagDisabledColumns,
    this.createSortableTextFilterColumn('ASSET-LIST.GEA-PUID', 'geaPUID'),
    this.createSortableTextFilterColumn('ASSET-LIST.ID', 'id'),
    this.rendererUtilService.createColumnDefinition('ASSET-LIST.SOURCE-SYS-ID', 'sourceSystemId'),
    this.createSortableTextFilterColumn('ASSET-LIST.MDG-CUSTOMER-NR', 'mdgCustomerNumber'),
    this.createSortableTextFilterColumn('ASSET-LIST.ITEMS-OR-TAG-ID', 'itemsOrTagId'),
    this.createSortableTextFilterColumn('ASSET-LIST.REGISTERED-PRODUCT-DESCRIPTION', 'registeredProductDescription'),
    this.rendererUtilService.createEnumColumn('ASSET-LIST.USER-STATUS', 'userStatus', UserStatus, this.USER_STATUS_PREFIX),
    this.rendererUtilService.createEnumColumn(
      'ASSET-LIST.SERVICE-STATUS',
      'serviceStatus',
      ServiceStatus,
      this.SERVICE_STATUS_PREFIX
    ),
    this.rendererUtilService.createColumnDefinition('ASSET-LIST.BUSINESS-CLASSIFICATION-L1', 'businessClassificationProdL1'),
    this.rendererUtilService.createColumnDefinition('ASSET-LIST.BUSINESS-CLASSIFICATION-L2', 'businessClassificationProdL2'),
    this.rendererUtilService.createColumnDefinition('ASSET-LIST.OBJECT-ID', 'objectId'),
    this.rendererUtilService.createEnumColumn(
      'ASSET-LIST.REGISTERED-PRODUCT-CATEGORY',
      'registeredProductCategory',
      RegisteredProductCategory,
      this.REGISTERED_PRODUCT_CATEGORY_PREFIX
    ),
    this.rendererUtilService.createEnumColumn(
      'ASSET-LIST.SYSTEM-STATUS',
      'systemStatus',
      SystemStatus,
      this.SYSTEM_STATUS_PREFIX
    ),
    this.rendererUtilService.createColumnDefinition('ASSET-LIST.WARRANTY-ID', 'warrantyId'),
    this.rendererUtilService.createColumnDefinition('ASSET-LIST.INSTALLED-BASE-ID', 'installedBaseId'),
    this.rendererUtilService.createColumnDefinition('ASSET-LIST.UPPER-ID', 'upperId'),
    this.createSortableColumn('ASSET-LIST.VALID-FROM', 'validFrom'),
    this.createSortableColumn('ASSET-LIST.VALID-TO', 'validTo'),
    this.createSortableColumn('ASSET-LIST.REFERENCE-DATE', 'referenceDate'),
    this.createSortableColumn('ASSET-LIST.COMMISSIONING-DATE', 'commissioningDate'),
    this.createSortableColumn('ASSET-LIST.HANDOVER-DATE', 'handoverDate'),
    this.createSortableColumn('ASSET-LIST.WARRANTY-START-DATE', 'warrantyStartDate'),
    this.createSortableColumn('ASSET-LIST.WARRANTY-END-DATE', 'warrantyEndDate'),
    this.createSortableColumn('ASSET-LIST.CREATION-DATE-TIME', 'creationDateTime'),
    this.createSortableColumn('ASSET-LIST.LAST-CHANGE-DATE-TIME', 'lastChangeDateTime'),
    this.createSortableColumn('ASSET-LIST.MANUFACTURER', 'manufacturer'),
    this.createSortableColumn('ASSET-LIST.MODEL-NUMBER', 'modelNumber'),
    this.createSortableColumn('ASSET-LIST.MANUFACTURER-PART-NO', 'manufacturerPartNo'),
    this.createSortableColumn('ASSET-LIST.MANUFACTURER-SERIAL-NO', 'manufacturerSerialNo'),
  ];
  /* eslint-enable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */

  tempUserColumns: ColumnDefinition[] = [this.createFilterRulesColumn('ASSET-LIST.EXCLUDED-BY-FILTER', 'excludedByFilter', true)];

  constructor(
    private tokenService: TokenService,
    private rendererUtilService: RendererUtilService
  ) {}

  public getColumns(featureFlagEnabled: boolean): ColumnDefinition[] {
    let columns: ColumnDefinition[] = [];
    if (featureFlagEnabled) {
      columns = [...columns, ...this.featureFlagEnabledColumns];
    } else {
      columns = [...columns, ...this.featureFlagDisabledColumns];
    }

    if (this.tokenService.isTempUser()) {
      columns = [...columns, ...this.tempUserColumns];
    }

    this.addHeaderTableSettings(columns);

    return columns;
  }

  addHeaderTableSettings(columnSettings: ColumnDefinition[]) {
    columnSettings.push({
      displayName: 'ASSET-LIST.TABLE-SETTINGS',
      key: 'tableSettings',
      frozen: 'right',
      sortable: false,
      resizeable: false,
      width: 50,
      headerRenderer: {
        component: VisibilitySettingsRendererComponent as HeaderRendererComponent<unknown>,
        config: {
          columns: [...columnSettings],
        },
      },
    });
  }

  createTranslatedColumn(displayName: string, key: string, translationPrefix: string, width = 150): ColumnDefinition {
    return {
      displayName,
      key,
      sortable: false,
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<unknown>,
        config: {
          prefix: translationPrefix,
        },
      },
      width,
    };
  }

  createSortableColumn(displayName: string, key: string, width = 150): ColumnDefinition {
    return { displayName, key, sortable: true, width };
  }

  createSortableTextFilterColumn(displayName: string, key: string, width = 150): ColumnDefinition {
    return { displayName, key, sortable: true, filter: { component: TextFilterComponent }, width };
  }

  createFilterRulesColumn(displayName: string, key: string, sortable: boolean, width = 150): ColumnDefinition {
    return {
      displayName,
      key,
      sortable,
      renderer: { component: FilterRulesRendererComponent as ColumnRendererComponent<unknown> },
      width,
    };
  }
}
