import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'advance-floating-action-button',
  templateUrl: './floating-action-button.component.html',
  styleUrls: ['./floating-action-button.component.scss'],
})
export class FloatingActionButtonComponent {
  @Input() show!: boolean;
  @Input() file!: string;
  @Output() buttonClicked = new EventEmitter<void>();

  public onClick(): void {
    this.buttonClicked.emit();
  }
}
