import { Component, DestroyRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ApiErrorResponse,
  ButtonV2Module,
  ColumnRendererComponent,
  ErrorHandlerV2Service,
  IconModule,
  SnackbarService,
} from '@gea/digital-ui-lib';
import { TranslateModule } from '@ngx-translate/core';
import { MappedJobInfo } from '../scheduled-jobs.component';
import { JobService } from '../../../../services/job.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlJobRendererService } from './control-job-renderer.service';

@Component({
  selector: 'advance-control-job-renderer',
  standalone: true,
  imports: [CommonModule, ButtonV2Module, TranslateModule, IconModule],
  templateUrl: './control-job-renderer.component.html',
  styleUrl: './control-job-renderer.component.scss',
})
export class ControlJobRendererComponent implements ColumnRendererComponent<MappedJobInfo> {
  rowData?: MappedJobInfo;
  sendingStartRequest = false;

  constructor(
    private jobService: JobService,
    private errorHandlerService: ErrorHandlerV2Service,
    private snackbarService: SnackbarService,
    private destroyed: DestroyRef,
    public controlJobRendererService: ControlJobRendererService
  ) {}

  startJob(jobName?: string) {
    if (!jobName) {
      // This case should never happen, just to be sure
      // eslint-disable-next-line no-console
      console.error('No JobName provided.');
      return;
    }

    this.sendingStartRequest = true;

    this.jobService
      .startJob(jobName)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe({
        next: () => {
          this.snackbarService.add({
            severity: 'success',
            summary: 'SUCCESS-SNACKBAR.SUCCESS',
            detail: 'SCHEDULED-JOBS.JOB-STARTED',
          });
          this.jobService.setJobStarted(this.rowData?.jobName);

          this.jobService.jobDataReloaded$.pipe(takeUntilDestroyed(this.destroyed)).subscribe(() => {
            this.sendingStartRequest = false;
          });
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
          this.sendingStartRequest = false;
        },
      });
  }
}
