import { Component, OnDestroy, OnInit } from '@angular/core';

import {
  ApiErrorResponse,
  ColumnDefinition,
  ErrorHandlerV2Service,
  FilterTableSettings,
  TableServiceV2,
} from '@gea/digital-ui-lib';
import { debounceTime, ReplaySubject, take, takeUntil, tap } from 'rxjs';

import { AssetService } from '../../services';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { Router } from '@angular/router';
import { AssetTableColumnService } from '../../services/asset-table-column.service';
import { Product, ProductHierarchyList, ProductList } from '../../api/v1';
import { TokenService } from '../../services/token.service';
import { MenuItem, TreeNode } from 'primeng/api';

@Component({
  selector: 'advance-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit, OnDestroy {
  readonly SHOW_ALL_COLUMNS_FEATURE: string = 'core_asset.list.show-all-columns.enabled';

  buttonItems: MenuItem[] = [
    {
      id: 'Single',
      label: 'ASSET-LIST.BUTTON-GROUP.SINGLE',
    },
    {
      id: 'Group',
      label: 'ASSET-LIST.BUTTON-GROUP.GROUP',
    },
  ];
  activeButtonItem: MenuItem = this.buttonItems[0];
  tableViewMode: 'Single' | 'Group' = 'Single';
  assetColumns: ColumnDefinition[] = [];
  flatTableFilterSettings: FilterTableSettings = { columns: {}, page: 0, pageSize: 10, search: false, searchValue: '' };
  hierarchyTableFilterSettings: FilterTableSettings = {
    columns: {},
    page: 0,
    pageSize: 10,
    search: false,
    searchValue: '',
  };
  products: Product[] = [];
  treeNodeProducts: TreeNode<Product>[] = [];
  totalRecordsFlat = 0;
  totalRecordsHierarchy = 0;
  loading = true;
  loadingFeatureConfiguration = false;
  searchInput = '';
  isTempUser = false;
  showExcludedProducts = false;
  tableSettingsUpdated = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private assetService: AssetService,
    private tableService: TableServiceV2,
    private errorHandlerService: ErrorHandlerV2Service,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    public assetTableColumnService: AssetTableColumnService,
    private tokenService: TokenService
  ) {}

  ngOnInit() {
    this.getFilterTableSettings();
    this.getFeatureFlagConfigurationForUser();

    this.isTempUser = this.tokenService.isTempUser();
  }

  getFilterTableSettings() {
    this.tableService
      .getFilterTableSettings(this.assetTableColumnService.FLAT_TABLE_ID)
      .pipe(
        takeUntil(this.destroyed$),
        tap(() => (this.loading = true)),
        debounceTime(250)
      )
      .subscribe((tableFilter) => {
        this.flatTableFilterSettings = tableFilter;
        this.applyFilter(tableFilter);
      });

    this.tableService
      .getFilterTableSettings(this.assetTableColumnService.HIERARCHY_TABLE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((tableFilter) => {
        this.hierarchyTableFilterSettings = tableFilter;
        if (this.tableViewMode === 'Group') {
          // If Only pagination is applied stay at the hierarchy table. If other filters are applied change to the flat table
          if (tableFilter.search || Object.keys(tableFilter.columns).length !== 0) {
            this.flatTableFilterSettings = tableFilter;
            this.tableViewMode = 'Single';
            this.activeButtonItem = this.buttonItems[0];
          } else {
            this.applyFilter(tableFilter);
          }
        }
      });
  }

  getFeatureFlagConfigurationForUser() {
    this.loadingFeatureConfiguration = true;
    this.featureFlagService
      .getFeatureFlagConfigurationForUser()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (featuresConfiguration) => {
          const featureFlagEnabled = this.SHOW_ALL_COLUMNS_FEATURE in featuresConfiguration.features;
          this.assetColumns = [...this.assetTableColumnService.getColumns(featureFlagEnabled)];
          this.loadingFeatureConfiguration = false;
        },
        error: (error: ApiErrorResponse) => {
          this.errorHandlerService.handleError(error);
          this.assetColumns = [...this.assetTableColumnService.getColumns(false)];
          this.loadingFeatureConfiguration = false;
        },
      });
  }

  applyFilter(tableFilter: FilterTableSettings) {
    this.loading = true;

    switch (this.tableViewMode) {
      case 'Single':
        return this.assetService
          .getProducts(this.flatTableFilterSettings, this.showExcludedProducts)
          .pipe(take(1))
          .subscribe({
            next: this.receiveProductData.bind(this),
            error: (error: ApiErrorResponse) => {
              this.loading = false;
              this.errorHandlerService.handleError(error);
            },
          });
      case 'Group':
        if (tableFilter.search || Object.keys(tableFilter.columns).length !== 0) {
          return (this.activeButtonItem = this.buttonItems[0]);
        }

        return this.assetService
          .getHierarchyProducts(this.hierarchyTableFilterSettings, this.showExcludedProducts)
          .pipe(take(1))
          .subscribe({
            next: this.receiveHierarchyProductData.bind(this),
            error: (error: ApiErrorResponse) => {
              this.loading = false;
              this.errorHandlerService.handleError(error);
            },
          });
    }
  }

  receiveProductData(products: ProductList) {
    this.products = products.pageEntries;
    this.totalRecordsFlat = products.entryCount;
    this.loading = false;
  }

  receiveHierarchyProductData(products: ProductHierarchyList) {
    this.treeNodeProducts = this.assetService.toProductHierarchyArray(products.pageEntries ?? []);
    this.totalRecordsHierarchy = products.entryCount;
    this.loading = false;
  }

  onRowClick(product: Product) {
    if (product.excludedByFilter) {
      return;
    }

    const geaPUID = product.geaPUID;
    if (geaPUID) {
      void this.router.navigate([`/assets/${product.geaPUID}`]);
    }
  }

  onSearchTicketsByClickEnter(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;
    this.onSearchAssets();
  }

  onToggleShowExcludedProducts(value: boolean) {
    this.showExcludedProducts = value;
    this.updateTableSettings();
  }

  onSearchAssets() {
    if (!this.searchInput) {
      this.clearSearch();
      return;
    }
    this.updateTableSettings();
  }

  clearSearch() {
    if (this.tableSettingsUpdated) {
      this.searchInput = '';
      this.clearTableSettings();
    }
  }

  resetFilterTableSettings(
    tableId: string,
    tableSettingsValue: FilterTableSettings = {
      columns: {},
      search: false,
      searchValue: '',
      page: 0,
      pageSize: 10,
    }
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.tableService.setFilterTableSettings(tableId, tableSettingsValue);
  }

  onChangeTableViewMode(item: MenuItem) {
    this.tableViewMode = (item?.id as 'Single' | 'Group') ?? 'Single';

    if (this.tableViewMode === 'Group') {
      this.searchInput = '';
      this.resetFilterTableSettings(this.assetTableColumnService.FLAT_TABLE_ID);
    } else if (this.tableViewMode === 'Single') {
      this.resetFilterTableSettings(this.assetTableColumnService.HIERARCHY_TABLE_ID);
      this.resetFilterTableSettings(this.assetTableColumnService.FLAT_TABLE_ID, {
        ...this.flatTableFilterSettings,
        search: this.searchInput !== '',
        searchValue: this.searchInput,
        page: 0,
        pageSize: 10,
      });
    }
  }

  updateTableSettings() {
    this.tableService.updateFilterTableSettings(this.assetTableColumnService.FLAT_TABLE_ID, {
      ...this.flatTableFilterSettings,
      columns: {},
      search: this.searchInput !== '',
      searchValue: this.searchInput,
      page: 0,
    });
    this.tableSettingsUpdated = true;
  }

  clearTableSettings() {
    this.tableService.updateFilterTableSettings(this.assetTableColumnService.FLAT_TABLE_ID, {
      columns: {},
      search: false,
      searchValue: '',
    });
    this.tableSettingsUpdated = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
