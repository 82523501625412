import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductAttributeGroup, ProductPassport, VisibilityType } from '../../../api/v1';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IconModule, TooltipV2Module } from '@gea/digital-ui-lib';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AccordionModule } from 'primeng/accordion';

export interface ExtendedProductPassport extends ProductPassport {
  attributeGroups: ExtendedProductAttributeGroup[];
}
export interface ExtendedProductAttributeGroup extends ProductAttributeGroup {
  showLoginInfoTooltip?: boolean;
  showLoginInfoTooltipAccordionHeader?: boolean;
  showInsufficientPermissionTooltip?: boolean;
  showAttributes: boolean;
}

@Component({
  selector: 'advance-detail-accordion',
  standalone: true,
  imports: [CommonModule, TranslateModule, TooltipV2Module, NgxSkeletonLoaderModule, AccordionModule, IconModule],
  templateUrl: './detail-accordion.component.html',
  styleUrl: './detail-accordion.component.scss',
})
export class DetailAccordionComponent {
  protected readonly VisibilityType = VisibilityType;
  readonly ATTRIBUTE_CATEGORY_TRANSLATION_PREFIX = 'ASSET-DETAIL.ATTRIBUTE-CATEGORY.';
  readonly ATTRIBUTE_TRANSLATION_PREFIX = 'ASSET-DETAIL.ATTRIBUTE.';

  _product!: ExtendedProductPassport;
  @Input() loading = false;
  @Input() isLoggedIn = false;
  @Input() isMobileView = false;
  @Input() set product(product: ProductPassport) {
    const extendedProductPassport = product as ExtendedProductPassport;
    this.extendAttributeGroups(extendedProductPassport?.attributeGroups);
    this._product = extendedProductPassport;
  }

  constructor(public translateService: TranslateService) {}

  extendAttributeGroups(attributeGroups: ExtendedProductAttributeGroup[]) {
    if (!attributeGroups) {
      return;
    }

    attributeGroups.forEach((group) => {
      const isPrivateGroup = group.visibilityType === VisibilityType.PRIVATE;
      const isMixedGroup = group.visibilityType === VisibilityType.MIXED;
      const hasNoAttributes = group.attributes?.length === 0;
      const showLoginInfo = this._product?.showLoginInfo;

      group.showInsufficientPermissionTooltip = isPrivateGroup && this.isLoggedIn && hasNoAttributes;
      group.showLoginInfoTooltip = isPrivateGroup && !this.isLoggedIn && showLoginInfo;
      group.showLoginInfoTooltipAccordionHeader = isMixedGroup && !this.isLoggedIn && showLoginInfo;
      group.showAttributes = !group.showInsufficientPermissionTooltip && !group.showLoginInfoTooltip;
    });
  }
}
