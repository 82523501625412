@if (!loading) {
  @if (showStatus && (isMobileView$ | async) === false) {
    <div class="status-container margin-top">
      <ng-container *ngTemplateOutlet="image"></ng-container>

      <div class="product-status">
        @for (status of productStatus; track status.property) {
          <div class="status">
            <div
              class="gea-font-heading-16"
              geauiTooltipOnEllipsisV2
              pTooltip="{{ 'ASSET-DETAIL.STATUS.' + status.translationKey | translate }}">
              {{ 'ASSET-DETAIL.STATUS.' + status.translationKey | translate }}
            </div>
            <div class="gea-font-subline-16" geauiTooltipOnEllipsisV2 pTooltip="{{ status.value ?? '-' }}">
              {{ status.value ?? '-' }}
            </div>
          </div>
        }
      </div>
    </div>
  } @else if (isMobileView$ | async) {
    <div class="margin-top">
      <ng-container *ngTemplateOutlet="image"></ng-container>
    </div>
  }
} @else {
  <ngx-skeleton-loader [theme]="{ height: '15rem', width: '100%' }" count="1" appearance="line"></ngx-skeleton-loader>
}

<ng-template #image>
  <div class="image" [ngClass]="{ 'desktop-view': (isMobileView$ | async) === false }">
    <img class="max-width" [src]="productImageLink" [alt]="'ASSET-DETAIL.PRODUCT-IMAGE-ALT-DESCRIPTION' | translate" />
  </div>
</ng-template>
