<p-accordion class="attributes-accordion" *ngIf="!loading || _product; else skeletonAccordion" data-cy="asset-detail-accordion">
  @for (attributeCategory of _product.attributeGroups; track attributeCategory.id) {
    <p-accordionTab [ngClass]="{ 'show-tooltip-banner': !attributeCategory.showAttributes }">
      <ng-template pTemplate="header">
        <div class="accordion-header" data-cy="accordion-header">
          <span>{{ ATTRIBUTE_CATEGORY_TRANSLATION_PREFIX + attributeCategory.name | translate }}</span>

          @if (attributeCategory.showLoginInfoTooltipAccordionHeader) {
            <div class="tooltip-info-badge" data-cy="accordion-header-login-tooltip">
              <geaui-icon-v2 [color]="'dark-blue'" file="16px_c-warning"></geaui-icon-v2>
              <span data-cy="label">{{ 'ASSET-DETAIL.CATEGORY-LOGIN.SHORT-INFO' | translate }}</span>
            </div>
          }
        </div>
      </ng-template>

      @if (attributeCategory?.showAttributes) {
        @for (attribute of attributeCategory.attributes; track attribute.name) {
          <div class="attribute" [ngClass]="{ 'attribute-bottom-space': !$last }" data-cy="accordion-attributes">
            <span class="gea-font-heading-16 bottom-margin" data-cy="title">
              {{ ATTRIBUTE_TRANSLATION_PREFIX + attribute.name + '.NAME' | translate }}
            </span>

            @switch (attribute.type) {
              @case ('Text') {
                <span class="gea-font-subline-16" data-cy="value">{{ attribute.value }}</span>
              }

              @case ('Translation') {
                <span class="gea-font-subline-16" data-cy="value">
                  {{ ATTRIBUTE_TRANSLATION_PREFIX + attribute.name + '.VALUE.' + attribute.value | translate }}
                </span>
              }

              @case ('Date') {
                <span class="gea-font-subline-16" data-cy="value">
                  {{ attribute.value | date: 'shortDate' : undefined : translateService.currentLang }}
                </span>
              }
            }
          </div>
        }
      } @else if (attributeCategory?.showLoginInfoTooltip) {
        <geaui-tooltip-v2
          [showIcon]="true"
          [title]="
            'ASSET-DETAIL.CATEGORY-LOGIN.INFO'
              | translate: { categoryName: ATTRIBUTE_CATEGORY_TRANSLATION_PREFIX + attributeCategory.name | translate }
          "
          type="blue">
        </geaui-tooltip-v2>
      } @else if (attributeCategory?.showInsufficientPermissionTooltip) {
        <geaui-tooltip-v2 [showIcon]="true" type="orange">
          <span data-cy="label">{{ 'ASSET-DETAIL.CATEGORY-PERMISSION.INFO' | translate }}</span>
          <a class="left-margin" href="mailto:customerportal@gea.com">{{ 'ASSET-DETAIL.CATEGORY-CONTACT' | translate }}</a>
        </geaui-tooltip-v2>
      }
    </p-accordionTab>
  }
</p-accordion>

<ng-template #skeletonAccordion>
  <ngx-skeleton-loader [theme]="{ height: '2rem', width: '100%' }" count="3" appearance="line"></ngx-skeleton-loader>
</ng-template>
