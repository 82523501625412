export * from './default-api.service';
import { DefaultApiService } from './default-api.service';
export * from './jobs-api.service';
import { JobsApiService } from './jobs-api.service';
export * from './products-api.service';
import { ProductsApiService } from './products-api.service';
export * from './productselection-api.service';
import { ProductselectionApiService } from './productselection-api.service';
export * from './public-api.service';
import { PublicApiService } from './public-api.service';
export const APIS = [DefaultApiService, JobsApiService, ProductsApiService, ProductselectionApiService, PublicApiService];
