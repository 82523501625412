/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Language = 'DE' | 'EN' | 'FR' | 'ES' | 'FI' | 'PL' | 'PT' | 'RU' | 'SL' | 'NL' | 'IT' | 'HU' | 'RO' | 'LT' | 'SR' | 'ZH' | 'DA' | 'EL' | 'CS' | 'JA' | 'HRV';

export const Language = {
    DE: 'DE' as Language,
    EN: 'EN' as Language,
    FR: 'FR' as Language,
    ES: 'ES' as Language,
    FI: 'FI' as Language,
    PL: 'PL' as Language,
    PT: 'PT' as Language,
    RU: 'RU' as Language,
    SL: 'SL' as Language,
    NL: 'NL' as Language,
    IT: 'IT' as Language,
    HU: 'HU' as Language,
    RO: 'RO' as Language,
    LT: 'LT' as Language,
    SR: 'SR' as Language,
    ZH: 'ZH' as Language,
    DA: 'DA' as Language,
    EL: 'EL' as Language,
    CS: 'CS' as Language,
    JA: 'JA' as Language,
    HRV: 'HRV' as Language
};

