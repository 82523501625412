/**
 * Asset app
 * Asset app
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type SystemStatus = 'NOT_FOUND' | 'IN_PREPARATION_1' | 'ACTIVE_2' | 'BLOCKED_3' | 'OBSOLETE_4';

export const SystemStatus = {
    NOT_FOUND: 'NOT_FOUND' as SystemStatus,
    IN_PREPARATION: 'IN_PREPARATION_1' as SystemStatus,
    ACTIVE: 'ACTIVE_2' as SystemStatus,
    BLOCKED: 'BLOCKED_3' as SystemStatus,
    OBSOLETE: 'OBSOLETE_4' as SystemStatus
};

