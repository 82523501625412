import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColumnRendererComponent, IconModule, SharedModule, SideModalV2Module } from '@gea/digital-ui-lib';
import { MappedJobInfo } from '../scheduled-jobs.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'advance-note-renderer',
  standalone: true,
  imports: [CommonModule, IconModule, SharedModule, TooltipModule, SideModalV2Module],
  templateUrl: './note-renderer.component.html',
  styleUrl: './note-renderer.component.scss',
})
export class NoteRendererComponent implements ColumnRendererComponent<MappedJobInfo> {
  rowData?: MappedJobInfo;
}
