import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { FilterTableSettings } from '@gea/digital-ui-lib';
import {
  DocumentGroup,
  HierarchyProduct,
  Product,
  ProductHierarchyList,
  ProductList,
  ProductPassport,
  ProductsApiService,
  PublicApiService,
} from '../api/v1';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(
    private productsApiService: ProductsApiService,
    private publicApiService: PublicApiService
  ) {}

  getProducts(
    tableFilter?: FilterTableSettings,
    showExcluded = false,
    applyOptionalExcludeRules = false
  ): Observable<ProductList> {
    const sortBy = this.getSortBy(tableFilter);
    const sortDir = this.getSortDir(tableFilter);
    const filter = this.getFilterParams(tableFilter);
    return this.productsApiService.getProducts(
      showExcluded,
      applyOptionalExcludeRules,
      tableFilter?.page,
      tableFilter?.pageSize,
      tableFilter?.searchValue,
      sortBy,
      sortDir,
      filter
    );
  }

  getHierarchyProducts(tableFilter?: FilterTableSettings, showExcluded = false): Observable<ProductHierarchyList> {
    return this.productsApiService.getHierarchyProducts(showExcluded, tableFilter?.page, tableFilter?.pageSize);
  }

  toProductHierarchyArray(hierarchyProducts: HierarchyProduct[]): TreeNode<Product>[] {
    return hierarchyProducts.map((hierarchyProduct) => this.toProductHierarchy(hierarchyProduct));
  }

  toProductHierarchy(hierarchyProduct: HierarchyProduct): TreeNode<Product> {
    return {
      data: hierarchyProduct.data,
      children: hierarchyProduct.children?.map((child) => this.toProductHierarchy(child)),
      expanded: false,
    };
  }

  getProduct(geaPUID: string, isLoggedIn: boolean): Observable<ProductPassport> {
    if (isLoggedIn) {
      return this.productsApiService.getProduct(geaPUID);
    } else {
      return this.publicApiService.getPublicProduct(geaPUID);
    }
  }

  getPublicProductDocuments(geaPUID: string): Observable<DocumentGroup[]> {
    return this.publicApiService.getPublicProductDocuments(geaPUID);
  }

  getSortBy(tableFilter?: FilterTableSettings): string | undefined {
    if (!tableFilter) {
      return undefined;
    }
    const sortByColumn = Object.keys(tableFilter.columns).find((key) => tableFilter.columns[key].sort);
    return sortByColumn ?? undefined;
  }

  getSortDir(tableFilter?: FilterTableSettings): string | undefined {
    if (!tableFilter) {
      return undefined;
    }
    const sortByColumn = Object.keys(tableFilter.columns).find((key) => tableFilter.columns[key].sort);
    return sortByColumn ? tableFilter.columns[sortByColumn].sort : undefined;
  }

  getFilterParams(tableFilter?: FilterTableSettings): string[] | undefined {
    if (!tableFilter) {
      return undefined;
    }
    const filterParams: string[] = [];
    for (const key in tableFilter.columns) {
      const filter = tableFilter.columns[key].filter ?? [];
      filter.forEach((filterValue) => {
        filterParams.push(`${key}[contains]=${filterValue}`);
      });
    }

    return filterParams ?? undefined;
  }
}
